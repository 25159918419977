/* Removed redundant styles */
.ordering-section p, .ordering-section ul {
    color: #333;
    font-size: 1em;
    margin-bottom: 20px;
}

.ordering-section a {
    color: #daa520;
    text-decoration: none;
}

.ordering-section a:hover {
    text-decoration: underline;
}

.ordering-section ul {
    list-style: none;
    padding: 0;
}

.ordering-section li {
    margin-bottom: 10px;
}

.note {
    font-style: italic;
    margin-top: 10px;
}
