.menu-wrapper {
    padding: 2rem 5%;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
}


.menu {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

/* ... Existing styles ... */

.section-title td {
    font-weight: bold;
    color: #2a2a2a;
    font-size: 1.2rem;
    background-color: #f3e5ab; /* Light golden background for the section titles */
    padding: 0.8rem 0;
    border-bottom: 3px solid #000; /* Dark black line under section titles */
}

.section-divider td {
    padding: 0.5rem 0;
}

.menu-table tr td:first-child {
    font-weight: 600; /* Bold for dish names */
}

.standalone-dish td {
    padding-top: 1.2rem; /* Additional padding for visual separation */
    border-top: 1px solid #d4af37; /* Golden line above standalone dishes */
}

.menu-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1rem 0;
    border-bottom: 1px solid #e0e0e0;
    opacity: 0;
    transform: translateY(50px); /* Adjust this value if needed */
    transition: all 0.5s ease-in-out;
    flex-direction: row; /* Make sure this is set to row */

}

.menu-section.visible {
    opacity: 1;
    transform: translateY(0);
}

.menu-section:last-child {
    border-bottom: none;
}



.menu-image {
    width: 250px;
    height: 150px;
    object-fit: cover;
    margin: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 2px solid black; /* Golden border to match your table styling */
    transition: border-color 0.3s ease-in-out; /* Smooth transition for hover effects */
}

.menu-image:hover {
    border-color: gold; /* Darker border on hover for a subtle effect */
}

.menu-text {
    flex: 1;
    padding: 1rem;
}

.menu-table {
    flex: 2;
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
}

.menu-table th,
.menu-table td {
    padding: 0.8rem 1rem;
    text-align: left;
    border-bottom: 1px solid #d4af37; /* Golden bottom border */
}

.menu-table th {
    background-color: #2a2a2a;
    color: #d4af37; /* Golden text */
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.menu-table td {
    background-color: #f5f5f5;
    color: #2a2a2a;
}

.menu-table tbody tr:hover td {
    background-color: #e0e0e0;
    transition: background-color 0.2s ease-in-out;
}

.italic-description {
    font-style: italic;
    margin-top: 0.5rem;
}

.menu-content {
    display: flex;
    flex-direction: column; /* To stack text and image vertically */
    align-items: center; /* To center-align text and image */
    flex: 1; /* To give it equal width as menu-table */
}


.left {
    order: 1;
}

.right {
    order: 2;
}

.menu-table tbody tr {
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.menu-table tbody tr:hover {
    background-color: gold;
    transform: scale(1.02);
}
/* For Mobile View */
@media (max-width: 768px) { /* Assuming 768px is the breakpoint for mobile */
    .menu-section {
        flex-direction: column; /* Stack them vertically */
    }
    
    .left, .right {
        order: 0; /* This ensures that the .menu-content (whether .left or .right) always appears above the table on mobile */
    }

    /* Adjustments for the menu content and table layout on mobile */
    .menu-content, .menu-table {
        margin: 0 auto; /* Center the content and table */
        max-width: 90%; /* Limit the width to 90% of the screen width */
    }
}

/* Adjust logo size for smaller devices */
@media screen and (max-width: 575px) {
    #logo {
        height: 50px;
        width: 50px;
    }

    .center-logo-quote {
        top: 25%;
        left: 50%;
        transform: translate(-50%, -25%);
    }

    .center-logo-img {
        width: 100px;
        height: 100px;
    }

    .quote {
        font-size: 14px;
        margin-top: 10px;
        padding: 0 15px; /* To ensure text doesn't touch screen edges */
    }
}

/* Specific adjustments for iPhone SE 2 */
@media screen and (max-width: 375px) {
    .center-logo-quote {
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .quote {
        font-size: 12px;
    }
}

/* Navbar adjustments */
@media (max-width: 767px) {
    .custom-navbar {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .navbar-custom {
        height: auto;
    }

    .navbar-toggler {
        padding-right: 10px;
    }
}
