.info-section {
    padding: 2rem 5%;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
    opacity: 0;
    transform: translateY(50px); 
    transition: all 1s ease-in-out;
}
.info-section.visible {
    opacity: 1;
    transform: translateY(0);
}

.inner-section {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.inner-section h2 {
    color: #2a2a2a;
    font-size: 2em;
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
}

.inner-section h2:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    width: 60px;
    height: 3px;
    background-color: #daa520;
}


/* .inner-section h2:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    height: 20px;
    width: 100%;
    background: linear-gradient(45deg, #EF8B17 25%, transparent 25%) 0 0, 
                linear-gradient(45deg, #EF8B17 25%, transparent 25%) 14px 0, 
                linear-gradient(-45deg, #EF8B17 25%, transparent 25%) 0 0, 
                linear-gradient(-45deg, #EF8B17 25%, transparent 25%) 14px 0;
    background-size: 21px 21px;
    background-color: transparent;
} */
/* Cleaner Underline for Section Headings */

.underline {
    position: relative;
    display: inline-block;
}

.underline::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    width: 60px;
    height: 3px;
    background-color: #daa520;
}
