/* Styling for Footer */
.footer {
    background-color: #000; /* Black Background */
    color: #fff; /* White Text Color */
    text-align: center;
    padding: 10px;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer .heart {
    width: 30px; /* or any suitable width */
    height: auto;
    vertical-align: middle;
    margin: 0 5px; /* optional, to add some space around the image */
}

.footer p {
    margin: 5px 0; /* Adjust the margin as per your design preference */
}
