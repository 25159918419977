/* Remove duplicate and redundant styles */
.contact-section p {
    color: #333;
    font-size: 1em;
    margin-bottom: 20px;
}

.contact-section a {
    color: #daa520;
    text-decoration: none;
}

.contact-section a:hover {
    text-decoration: underline;
}
