/* Testimonials Styles */

.inner-section {
    padding: 60px 0;
    background-color: #f9f9f9;
}

.testimonial-heading {
    text-align: center;
    margin-bottom: 40px;
    color: #444;
}

.testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 20px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.testimonial-textbox {
    color: #333;
    padding: 20px 30px;
    margin-bottom: 15px;
}

.testimonial p {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.5;
}

.author {
    font-weight: bold;
    color: #555;
}

.stars {
    color: #daa520;
    font-size: 24px;
    margin-bottom: 10px;
}
/* Add this to your Testimonials.css */
.testimonial.visible {
    opacity: 1;
    transition: all 1s ease-in-out;
    transform: translateY(0);

}

/* Existing Testimonials Styles... */

.testimonial-heading {
    text-align: center;
    margin-bottom: 40px;
    color: #444;
    position: relative; /* Required for positioning the pseudo-element */
    padding-bottom: 10px; /* Space for the underline */
}

/* Media Queries */
@media (max-width: 768px) {
    .testimonial {
        max-width: 100%;
    }
}
