body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

/* ... (other styles) ... */
.header-section {
  background-image: url('/Charminar.jpeg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  color: white;
}
/* ... (other styles) ... */


.other-sections {
  background-color: #f5f5dc; /* Beige background */
  padding: 20px;
}
* {
  padding: 0;
}


/* Common Styling for Info Sections */
.info-section {
  background-color: #f8f4dc; /* Whitish/Beige Background */
  padding: 20px;
  text-align: center;
}

.info-section h2 {
  color: #daa520; /* Golden Text Color */
  font-size: 2em;
  margin-bottom: 20px;
}

.info-section p, .info-section ul {
  color: #333; /* Dark Text Color */
  font-size: 1em;
  margin-bottom: 20px;
}

.info-section a {
  color: #daa520; /* Golden Text Color for Links */
  text-decoration: none;
}

.info-section a:hover {
  text-decoration: underline;
}

.info-section ul {
  list-style: none;
  padding: 0;
}

.info-section li {
  margin-bottom: 10px;
}

.note {
  font-style: italic;
  margin-top: 10px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* Golden Text Color for Links *//* Golden Text Color for Links */
.header-section {
    background-image: url('/Charminar.jpeg');
    background-size: 100%;
    background-position: center 65%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    min-height: 100vh;
    color: white;
    z-index: 1;
}


@media (max-width: 767px) {
    .header-section {
        background-size: 160%;
        background-position: bottom center;
        background-attachment: scroll;
        background-color: black;
    }
}


@media screen and (max-width: 900px) {
    #logo {
        height: 75px;
        width: 75px;
    }
}

.custom-navbar {
    background-color: black !important;
}

.logo-img {
    width: 60px;
    height: 60px;
}

.center-logo-quote {
    text-align: center;
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -30%);
            transform: translate(-50%, -30%);
}

.center-logo-img {
    width: 150px;
    height: 150px;
}

.quote {
    margin-top: 20px;
    font-style: italic;
    font-weight: bold;
    color: #daa520;
}
.custom-nav-link {
    color: #daa520 !important; /* !important is used to override the Bootstrap styles */
}


/* Below are the additional styles that were present in your original CSS, 
   you might want to review and merge them as per your needs */

.navbar-custom {
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    
}


.navbar-custom .navbar-nav .nav-link {
    color: #daa520; 
    margin-right: 20px; 
}

.navbar-custom .navbar-brand {
    margin-left: auto; 
    padding-right: 10px; 
}

.navbar-custom .navbar-toggler {
    border: none;
    padding-right: 20px;
}

.navbar-custom .navbar-toggler-icon {
    background-color: #daa520;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(218, 165, 32, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (max-width: 767px) {
    .navbar-custom {
        height: 60px;
    }
}

.menu-wrapper {
    padding: 2rem 5%;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
}


.menu {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

/* ... Existing styles ... */

.section-title td {
    font-weight: bold;
    color: #2a2a2a;
    font-size: 1.2rem;
    background-color: #f3e5ab; /* Light golden background for the section titles */
    padding: 0.8rem 0;
    border-bottom: 3px solid #000; /* Dark black line under section titles */
}

.section-divider td {
    padding: 0.5rem 0;
}

.menu-table tr td:first-child {
    font-weight: 600; /* Bold for dish names */
}

.standalone-dish td {
    padding-top: 1.2rem; /* Additional padding for visual separation */
    border-top: 1px solid #d4af37; /* Golden line above standalone dishes */
}

.menu-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1rem 0;
    border-bottom: 1px solid #e0e0e0;
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px); /* Adjust this value if needed */
    transition: all 0.5s ease-in-out;
    flex-direction: row; /* Make sure this is set to row */

}

.menu-section.visible {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.menu-section:last-child {
    border-bottom: none;
}



.menu-image {
    width: 250px;
    height: 150px;
    object-fit: cover;
    margin: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 2px solid black; /* Golden border to match your table styling */
    transition: border-color 0.3s ease-in-out; /* Smooth transition for hover effects */
}

.menu-image:hover {
    border-color: gold; /* Darker border on hover for a subtle effect */
}

.menu-text {
    flex: 1 1;
    padding: 1rem;
}

.menu-table {
    flex: 2 1;
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
}

.menu-table th,
.menu-table td {
    padding: 0.8rem 1rem;
    text-align: left;
    border-bottom: 1px solid #d4af37; /* Golden bottom border */
}

.menu-table th {
    background-color: #2a2a2a;
    color: #d4af37; /* Golden text */
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.menu-table td {
    background-color: #f5f5f5;
    color: #2a2a2a;
}

.menu-table tbody tr:hover td {
    background-color: #e0e0e0;
    transition: background-color 0.2s ease-in-out;
}

.italic-description {
    font-style: italic;
    margin-top: 0.5rem;
}

.menu-content {
    display: flex;
    flex-direction: column; /* To stack text and image vertically */
    align-items: center; /* To center-align text and image */
    flex: 1 1; /* To give it equal width as menu-table */
}


.left {
    order: 1;
}

.right {
    order: 2;
}

.menu-table tbody tr {
    transition: background-color 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.menu-table tbody tr:hover {
    background-color: gold;
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}
/* For Mobile View */
@media (max-width: 768px) { /* Assuming 768px is the breakpoint for mobile */
    .menu-section {
        flex-direction: column; /* Stack them vertically */
    }
    
    .left, .right {
        order: 0; /* This ensures that the .menu-content (whether .left or .right) always appears above the table on mobile */
    }

    /* Adjustments for the menu content and table layout on mobile */
    .menu-content, .menu-table {
        margin: 0 auto; /* Center the content and table */
        max-width: 90%; /* Limit the width to 90% of the screen width */
    }
}

/* Adjust logo size for smaller devices */
@media screen and (max-width: 575px) {
    #logo {
        height: 50px;
        width: 50px;
    }

    .center-logo-quote {
        top: 25%;
        left: 50%;
        -webkit-transform: translate(-50%, -25%);
                transform: translate(-50%, -25%);
    }

    .center-logo-img {
        width: 100px;
        height: 100px;
    }

    .quote {
        font-size: 14px;
        margin-top: 10px;
        padding: 0 15px; /* To ensure text doesn't touch screen edges */
    }
}

/* Specific adjustments for iPhone SE 2 */
@media screen and (max-width: 375px) {
    .center-logo-quote {
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }

    .quote {
        font-size: 12px;
    }
}

/* Navbar adjustments */
@media (max-width: 767px) {
    .custom-navbar {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .navbar-custom {
        height: auto;
    }

    .navbar-toggler {
        padding-right: 10px;
    }
}

.info-section {
    padding: 2rem 5%;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px); 
    transition: all 1s ease-in-out;
}
.info-section.visible {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.inner-section {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.inner-section h2 {
    color: #2a2a2a;
    font-size: 2em;
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
}

.inner-section h2:after {
    content: '';
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: -10px;
    width: 60px;
    height: 3px;
    background-color: #daa520;
}


/* .inner-section h2:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    height: 20px;
    width: 100%;
    background: linear-gradient(45deg, #EF8B17 25%, transparent 25%) 0 0, 
                linear-gradient(45deg, #EF8B17 25%, transparent 25%) 14px 0, 
                linear-gradient(-45deg, #EF8B17 25%, transparent 25%) 0 0, 
                linear-gradient(-45deg, #EF8B17 25%, transparent 25%) 14px 0;
    background-size: 21px 21px;
    background-color: transparent;
} */
/* Cleaner Underline for Section Headings */

.underline {
    position: relative;
    display: inline-block;
}

.underline::after {
    content: '';
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: -10px;
    width: 60px;
    height: 3px;
    background-color: #daa520;
}

/* Removed redundant styles */
.ordering-section p, .ordering-section ul {
    color: #333;
    font-size: 1em;
    margin-bottom: 20px;
}

.ordering-section a {
    color: #daa520;
    text-decoration: none;
}

.ordering-section a:hover {
    text-decoration: underline;
}

.ordering-section ul {
    list-style: none;
    padding: 0;
}

.ordering-section li {
    margin-bottom: 10px;
}

.note {
    font-style: italic;
    margin-top: 10px;
}

/* Remove duplicate and redundant styles */
.contact-section p {
    color: #333;
    font-size: 1em;
    margin-bottom: 20px;
}

.contact-section a {
    color: #daa520;
    text-decoration: none;
}

.contact-section a:hover {
    text-decoration: underline;
}

/* Styling for Footer */
.footer {
    background-color: #000; /* Black Background */
    color: #fff; /* White Text Color */
    text-align: center;
    padding: 10px;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer .heart {
    width: 30px; /* or any suitable width */
    height: auto;
    vertical-align: middle;
    margin: 0 5px; /* optional, to add some space around the image */
}

.footer p {
    margin: 5px 0; /* Adjust the margin as per your design preference */
}

/* Testimonials Styles */

.inner-section {
    padding: 60px 0;
    background-color: #f9f9f9;
}

.testimonial-heading {
    text-align: center;
    margin-bottom: 40px;
    color: #444;
}

.testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 20px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.testimonial-textbox {
    color: #333;
    padding: 20px 30px;
    margin-bottom: 15px;
}

.testimonial p {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.5;
}

.author {
    font-weight: bold;
    color: #555;
}

.stars {
    color: #daa520;
    font-size: 24px;
    margin-bottom: 10px;
}
/* Add this to your Testimonials.css */
.testimonial.visible {
    opacity: 1;
    transition: all 1s ease-in-out;
    -webkit-transform: translateY(0);
            transform: translateY(0);

}

/* Existing Testimonials Styles... */

.testimonial-heading {
    text-align: center;
    margin-bottom: 40px;
    color: #444;
    position: relative; /* Required for positioning the pseudo-element */
    padding-bottom: 10px; /* Space for the underline */
}

/* Media Queries */
@media (max-width: 768px) {
    .testimonial {
        max-width: 100%;
    }
}

